@mixin container {
  margin: auto;
  width: 100%;
  max-width: 1024px;
  padding: $margin-md;
  margin: 0 auto;
}

@mixin page {
  position: relative;
  margin: auto;
  flex: 1;
  padding: $margin-md;
  margin: 0 auto;
  overflow: auto;
  max-height: 100%;
}

@mixin containerField {
  margin-bottom: $margin-md;
}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@mixin dotLoader() {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: $primary-color;
  border-radius: 7px;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-name: rotate;
  margin-right: $margin-sm;
}
@keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0);
  }
  50% {
    transform: rotateX(-180deg) rotateY(0);
  }
  100% {
    transform: rotateX(-180deg) rotateY(-180deg);
  }
}
