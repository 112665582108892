@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: auto;
  background-color: #F9FAFF;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include container();
    flex: 1;

    .header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-md;

      h1 {
        font-family: 'Poppins-SemiBold';
        margin: 0;
      }

      svg {
        color: $text-color;
      }

      +div {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }

  }

}
