@import "../../styles/_variables.scss";

.inputFile {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  // border: 1px dashed $primary-color;
  // background: rgba(106, 162, 135, 0.12);
  width: 100%;
  height: 39px;
  border-radius: 8px;

  input + div:not(.dropZone) {
    background-color: rgba(110, 113, 119, 0.2);
    box-shadow: 0 0 0 4px rgba(110, 113, 119, 0.2);
    border: none;
    border-radius: $radius;
    text-align: center;
  }

  // input + div:not(.dropZone) + .dropZone {
  //   opacity: 0;
  // }

  > .dropZone {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // flex: 1;
      height: 39px;
      label {
        color: $text-light;
      }
    }

    .containerButton {
      justify-content: flex-end;
      max-width: 100%;
      min-width: 1px;
      flex: 1;
      button {
        justify-content: flex-start;
        cursor: pointer;
        max-width: 230px;
        overflow: hidden;
        // &.withFile {
        //   border: solid 1px rgba(110, 113, 119, 0.3);
        //   background-color: $bg-color;
        //   color: $text-color;
        // }
        &.disabled {
          border: solid 1px $light-grey;
          background-color: $light-grey;
          pointer-events: none;
        }
        .filesName {
          display: inline-block;
          overflow: hidden;
          width: 100%;
          max-width: calc(100% - 20px);
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        >span {
         pointer-events: none;
        }
      }
      .removeFile {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $warn;
        color: $white;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        margin-left: -10px;
        transition: background-color 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          background-color: darken($warn, 5);
        }
        svg {
          position: relative;
          transform: rotate(45deg);
          pointer-events: none;
        }
      }
    }
  }
}
