@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.notifications {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: auto;
  background-color: #F9FAFF;

  .container {
    @include container();
    flex: 1;

    .header {
      position: relative;
      margin-bottom: $margin-md;

      a {
        position: absolute;
      }

      h1 {
        font-family: 'Poppins-SemiBold';
        margin: 0;
        text-align: center;
      }

      svg {
        color: $text-color;
      }

      +div {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }

    ul {
      margin: 0;
      padding: 0;

      .notification {
        position: relative;
        list-style: none;
        padding: $margin-sm $margin-md;
        background-color: $white;
        border: solid 1px $white;
        border-radius: $radius;
        box-shadow: 0px 10px 60px rgba(195, 202, 209, 0.5);

        &:not(.read) {
          cursor: pointer;
        }

        &.read {
          border-color: rgba($text-light, 0.5);
          background-color: #F9FAFF;
          box-shadow: none;
        }

        &:not(:last-child) {
          margin-bottom: $margin-sm;
        }

        >div {
          padding-right: $margin-md;
        }

        p {
          font-family: 'Poppins-Medium';
          margin: 0;
        }

        .date {
          color: $text-light;
          margin-top: 5px;
        }

        .dot {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: $secondary-color;
        }

      }
  
    }
  }

}
