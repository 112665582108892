@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: auto;
  .container:first-child {
    @include container();
    padding: 0;

    .header {
      background-color: $primary-color;
      border-radius: 0 0 40px 40px;
      padding: $margin-lg;

      .containerLogo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $margin-lg;

        img {
          max-width: 220px;
          width: 100%;
        }

        .notifications {
          margin-left: $margin-sm;
        }
      }

      h1 {
        font-family: "Poppins-SemiBold";
        color: $white;
        margin: 0 0 $margin-sm 0;
      }

      .deliveryRound {
        position: relative;
        .loader>div {
          position: relative;
          left: 0;
          top: 0;
        }

        h3, p {
          color: $white;
        }

        h3 {
          margin: 0 0 $margin-xs 0;
        }

        p {
          margin: 0 0 3px 0;
        }

        .col {
          display: inline-block;
          max-width: 33%;
          &:not(:nth-child(2)) {
            margin-left: $margin-md;
          }

          .color {
            display: inline-block;
            width: 20px;
            height: 12px;
            border-radius: 6px;
            margin-left: 5px;
          }

          >p {
            font-family: "Poppins-SemiBold";
            &:first-child {
              color: $text-light;
            }
          }
        }

      }

    }
  }

  .container {
    @include container();
    flex: 1;

    .content {
      margin-bottom: $margin-md;

      >img {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 400px;
      }

      .clock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 10px 60px rgba(195, 202, 209, 0.5);
        border-radius: 10px;
        padding: $margin-md $margin-lg;
        text-decoration: none;
        cursor: pointer;
        h2 {
          margin: 0;
          text-decoration: none;
        }

        img {
          margin-top: -60px;
        }

      }
    }
  }

}
