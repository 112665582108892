@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.form {
  position: relative;
  width: 100%;

  .container-field {
    @include containerField();
    input {
      max-width: 200px;
      text-align: center;
    }

    &.comments {
      margin-top: $margin-lg;
    }

  }

  .containerSubmit {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: $margin-sm;

    .errorMessage {
      margin-top: $margin-xs;
    }

    .actions {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-family: 'Poppins-SemiBold';
        margin: 0 $margin-lg 0 0;
        color: $warn;
        text-decoration: underline;
        cursor: pointer;
      }
    }


  }

}