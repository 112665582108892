@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-textarea{
  margin: 0px 0px 0px 0px;

  label{
    @include label;
  }

  &.login:not(.inline) label {
    background-color: $grey;
    color: $white;
  }

  &.inline{
    @include inline;
    align-items: flex-start;
    label {
      margin-top: 12px;
    }
  }

  &:not(.inline){
    label+.container{
      margin-top: 10px;
    }
  }

  &.disabled{
    pointer-events: none;
    opacity: .5;
  }

  .input {
    @include input;

    padding-top: 16px;
    padding-bottom: 16px;
    resize: none;

    &.disabled{
      pointer-events: none;
    }
  }

  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

  .container{
    width: 100%;
    .count-characters{
      text-align: right;
      font-size: 12px;
      margin: 0;
      padding: 0;
    }

  }





}
