$bg-color: #FAFBFC;
$white: #FFFFFF;
$primary-color: #213B8A;
$secondary-color: #A8C825;
$success: #5BC284;
$warn: #FA5C4F;

$white: #FFFFFF;


$light-grey: #E6E8EA;
$grey: #404B52;

$black: #05004E;
$text-light: #96A5B8;
$text-color: #05004E;

$xl-screen: 1200px;
$lg-screen: 1024px;
$md-screen: 900px;
$sm-screen: 700px;
$xs-screen: 576px;

$margin-xl : 50px;
$margin-lg : 30px;
$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;


$h1: 28px;
$h2: 22px; 
$h3: 16px;
$button: 14px;
$regular: 14px;
$radius: 10px;

@font-face {
	font-family: "Poppins-Bold";
	src: url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-Medium";
	src: url("../assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-SemiBold";
	src: url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
}