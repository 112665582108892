@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.todayForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $primary-color;
  padding: $margin-md;

  .close {
    margin-left: auto;
    color: $secondary-color;
    padding: 3px;
    margin-top: -10px;
    svg {
      position: relative;
      top: calc(50% - 3px);
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  .today {
    background-color: rgba(255, 255, 255, 0.5);
    padding: $margin-md 0;
    border-radius: $radius;
    margin: 0 0 $margin-md;
    p {
      font-family: 'Poppins-SemiBold';
      text-align: center;
      margin: 0;
      color: $white;
    }
  }

  .step {
    display: flex;
    justify-content: center;
    padding: $margin-md 0;
    margin: 0 0 0 0;
    .dot {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      margin: 0 5px;
      transition: background-color .4s ease-in-out;
      &.selected {
        background-color: $secondary-color;
      }
    }
  }

  .content {
    flex: 1;
    h3 {
      color: $secondary-color;
      text-align: center;
      margin: 0 0 $margin-sm 0;
    }

    h2 {
      color: $white;
      text-align: center;
      margin: 0 0 $margin-sm 0;
    }

    .target {
      margin-top: $margin-lg;
      input {
        background-color: $black;
        border-color: $black;
        padding: $margin-md;
        font-family: 'Poppins-Bold';
        font-size: $h2;
        color: $white;
        text-align: center;
        max-width: 200px;
        margin: 0 auto;
        ::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

  }

  .footer {
    display: flex;
    justify-content: space-between;

    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 83px;
      height: 83px;
      border-radius: 10px;
      background-color: $black;
      color: $white;
      font-size: 30px;
      transition: all .4s ease-in-out;

      &:last-child {
        color: $text-color;
        background-color: $white;
        .loader {
          position: relative;
          top: 6px;
          left: 5px;
          // color: $white;
          // top: 2px;
        }
        &:not(.active) {
          pointer-events: none;
        }
      }

      &.active {
        background-color: $secondary-color;
        color: $white;
      }
    }

  }


}