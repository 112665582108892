@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.reset-password {
  width: 100%;
  .truck {
    background-color: #EAEDF9;
    .container {
      @include container();
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100%;
        &:first-child {
          max-width: 256px;
          margin-top: $margin-sm;
          margin-bottom: $margin-lg;
        }
        &:last-child {
          max-width: 325px;
        }
      }
    }
  }
  >.container {
    @include container();
    h1 {
      display: block;
      font-family: "Poppins-SemiBold";
      color: $text-color;
      margin: 0 auto $margin-md auto;
      max-width: 485px;
      width: 100%;
      +div {
        max-width: 485px;
        margin: 0 auto;
      }
    }
  }
}