@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  h3 {
    margin: 0 0 $margin-sm 0;
  }

  .col {
    width: 100%;
  }
  .container-field {
    @include containerField();

    &.licenseImg {
      .licenseImgInfos {
        display: flex;
        margin-bottom: $margin-sm;
        p {
          margin: 0;
          white-space: nowrap;
          &:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: $margin-xs;
          }
        }

        p:last-child {
          color: $text-light;
        }
      }
      button {
        width: 100%;
        max-width: 100% !important;
      }
    }
  }

  .containerSubmit {
    width: 100%;
    button {
      width: 100%;
    }
  }
}
