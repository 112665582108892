@import "../../styles/_variables.scss";

.toastUpdate {
  position: fixed;
  bottom: 0;
  z-index: 1000;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color;
  width: 100%;
  padding: $margin-md;
  animation: toastIn 1 0.4s;

  @keyframes toastIn {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }

  p {
    font-family: 'Poppins-SemiBold';
    color: $white;
    margin: 0;
  }
}