@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.profile {
  flex: 1;
  p {
    font-family: 'Poppins-Medium';
    color: $text-light;
    span {
      color: $text-color;
    }
  }

  .user {
    background-color: $white;
    box-shadow: 0px 10px 60px rgba(195, 202, 209, 0.5);
    border-radius: 15px;
    margin-top: $margin-md;

    .content {
      position: relative;
      padding: $margin-md $margin-md 0 $margin-md;

      .edit {
        position: absolute;
        right: $margin-md;
        color: $text-color;
      }

      .profilePicture {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        min-width: 100px;
        height: 100px;
        min-height: 100px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        margin: -50px auto $margin-sm auto;
        background-color: $text-light;
        cursor: pointer;

        .editPicture {
          position: absolute;
          top: 7px;
          left: -7px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $black;
          color: $white;
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }

        p {
          position: relative;
          top: -3px;
          color: $white;
          text-transform: uppercase;
          font-size: 40px;
          margin: 0;
        }

      }

      h2 {
        margin: 0;
        text-align: center;
      }

      h4 {
        margin: 0 0 $margin-md 0;
        text-align: center;
        font-size: $button;
      }

    }
    button {
      width: 100%;
    }
  }

  .driverLicense {
    margin-bottom: $margin-md;
    button {
      width: 100%;
    }
  }

}
