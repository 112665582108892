@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.edit {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .container {
    @include container();
    flex: 1;
  }

}
