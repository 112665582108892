@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.timetable {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: auto;

  .container {
    @include container();
  }

  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-family: 'Poppins-SemiBold';
      margin: 0;
    }
  }

  .navigation {
    width: 100%;
    margin-top: $margin-sm;

    .navMonths {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: $margin-md;

      > div {
        margin-right: auto;

        p {
          font-family: 'Poppins-SemiBold';
          color: $text-light;
          margin: 0;
        }

        h2 {
          font-family: 'Poppins-SemiBold';
          text-transform: capitalize;
          margin: 0;
        }
      }

      button {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        padding: 0;
        border: none;
        background-color: $white;

        svg {
          position: relative;
          top: 3px;
        }

        &:last-child {
          margin-left: $margin-sm;
        }
      }
    }

    .navDays {
      width: 100%;

      ul {
        display: flex;
        // justify-content: flex-end;
        padding: 50px 50px 60px 50px;
        margin: -50px calc(-1 * $margin-md) -30px calc(-1 * $margin-md);
        width: calc(100% + calc(2 * $margin-md));
        overflow-x: auto;
        scroll-snap-type: x proximity;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        li {
          list-style: none;
          scroll-snap-align: end;
          padding-right: $margin-md;
          &:first-child {
            margin-left: auto;
          }

          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            width: 83px;
            min-width: 83px;
            height: 83px;
            background-color: $white;
            box-shadow: 0px 10px 60px rgba(195, 202, 209, 0.5);
            border-radius: 10px;
            text-align: center;
            cursor: pointer;
            transition: all .3s ease-in-out;
            @include remove-tap-highlight();
            @include remove-highlight();

            &.selected {
              background-color: $primary-color;
              p {
                color: $white;
              }
            }


            p {
              margin: 0;
              transition: all .3s ease-in-out;
              > span {
                font-family: 'Poppins-SemiBold';
                font-size: $h2;
              }
            }
          }
        }
      }
    }
  }
}
