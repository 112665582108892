@import "../../styles/_variables.scss";

.bell {
  position: relative;
  .dot {
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $secondary-color;
  }
}