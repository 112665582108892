@import '../../../../styles/variables';

.resetpwd-form{
  max-width: 485px;

  .form{
    // margin-bottom: $margin-lg;
    .container-field{
      margin: 0 0 15px 0;
    }
    .message-error{
      color : $warn;
      font-size: 12px;
      margin-top: $margin-md;
    }
    .message-succeed{
      font-family: 'Poppins-Medium';
      color : $secondary-color;
      font-size: $regular;
      margin-top: $margin-md;
    }

    .submit {
      margin-top: $margin-lg;
    }

    button.btnSucceed{
      max-width: 100%;
      white-space: nowrap;
    }

  }

}
